import React from 'react';
import PropTypes from 'prop-types';

const IntroBlock = ({ title, text }) => (
  <div className="intro-block">
    <h1 className="intro-title">{title}</h1>
    {text && <p className="intro-text">{text}</p>}
  </div>
);

IntroBlock.defaultProps = {
  title: 'Wat is DigiD',
  text:
    'Met DigiD laat u zien wie u bent. DigiD is uw online identiteitsbewijs, een soort digitaal paspoort voor de overheid en semi-overheid, zoals uw zorgverzekeraar of uw pensioenfonds. Met DigiD kunt u uw zaken met deze organisaties online regelen. DigiD is niet verplicht, maar wel makkelijk.',
};

IntroBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default IntroBlock;
