import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { v4 as uuid } from 'uuid';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import Chevron from '../../assets/icons/chevron_right.svg';
import _ from 'lodash';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

const eachDeep = require('deepdash/eachDeep');

const Breadcrumbs = ({parentPages, childPages}) => {
    return (
        <div className="breadcrumb-container">
            <nav aria-labelledby="label-breadcrumb" className="breadcrumbs">
                <h3 id="label-breadcrumb" className="sr-only">Breadcrumb</h3>
                <ul>
                    {parentPages.map(item => {
                        return (
                            <li key={uuid()} className={item.current ? 'parent-current' : 'parent'}>
                                <Link to={item.url}>
                                    {item.name}
                                </Link>
                                {item.current && childPages.length > 0 &&
                                    <ul>
                                        {childPages.map(item => {
                                            return (
                                                <li key={uuid()} className={cn("child", {current: item.current})}>
                                                    <Link to={item.url} aria-current={item.current ? "page" : null}>
                                                        {item.name}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                }
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    )
}

Breadcrumbs.propTypes = {
    parentPages: PropTypes.arrayOf(PropTypes.object),
    childPages: PropTypes.arrayOf(PropTypes.object)
}

const BreadCrumbsMobile = ({parentPages, childPages, withCurrent}) => {
    const {t} = useTranslation();
    const currentChild = childPages.find(item => item.current === true);
    return (
        <nav aria-labelledby="label-breadcrumb-mobile"
             className={withCurrent ? 'with-current breadcrumbs-mobile' : 'breadcrumbs-mobile'}>
            <h3 id="label-breadcrumb-mobile" className="sr-only">Breadcrumb</h3>
            <ul className={withCurrent ? 'with-current' : undefined}>
                {parentPages.map(item => {
                    return (
                        <li key={uuid()} className={(item.currentMobile === true || typeof item.currentMobile === 'undefined') ? 'current' : undefined}>
                            <Link to={item.url}>
                                <svg viewBox="0 0 32 32">
                                    <path fill="#0958b3"
                                          d="M22.41,27.4l-.06.07a1.83,1.83,0,0,1-2.58,0L9.59,17.29a1.83,1.83,0,0,1,0-2.58L19.77,4.53a1.83,1.83,0,0,1,2.58,0l.06.07a1.83,1.83,0,0,1,0,2.58L13.59,16l8.82,8.82a1.83,1.83,0,0,1,0,2.58"></path>
                                </svg>
                                {item.name}
                            </Link>
                        </li>
                    )
                })}
            </ul>
            {childPages.length > 0 && !_.find(childPages, {current: true}) && (
                <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <div className="title">
                                    <span className="accordion__arrow"/>
                                    {parentPages.slice(-1).map((item, key) => {
                                        return (
                                            <span key={key}>{item.name}</span>
                                        )
                                    })}
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {childPages.map(item => (
                                <div key={uuid()}>
                                    <Link to={item.url}><Chevron/>{item.name}</Link>
                                </div>
                            ))}
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            )}
            {currentChild && (
                <div className="accordion">
                    <div className="accordion__item">
                        <div className="accordion__heading">
                            <div className="accordion__button" aria-expanded="true">
                                <div className="title">
                                    <span className="accordion__arrow"/>
                                    {currentChild.name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    )
}

BreadCrumbsMobile.propTypes = {
    parentPages: PropTypes.arrayOf(PropTypes.object),
    childPages: PropTypes.arrayOf(PropTypes.object),
    withCurrent: PropTypes.bool,
}

const BreadCrumbsWrapper = ({currentPath, language, breadcrumbs = []}) => {
    const parentPages = [{name: 'Home', url: (language === 'en' ? '/en' : '/'), currentMobile: false}]
    const childPages = [];

    eachDeep(breadcrumbs, (obj, key, parent, context) => {
        if (_.get(obj, 'url.path') === currentPath) {
            context.parents.forEach(parent => {
                if (_.get(parent.value, 'url.path')) {
                    parentPages.push({
                        name: _.get(parent.value, 'label'),
                        url: _.get(parent.value, 'url.path'),
                        currentMobile: _.get((parent.value, 'url.path') === currentPath) ? true : false
                    })
                }
            })

            if (_.isEmpty(obj.links)) {
                parentPages[parentPages.length - 1].current = true;
                _.forEach(parent.links, (link) => {
                    childPages.push({
                        name: _.get(link, 'label'),
                        url: _.get(link, 'url.path'),
                        current: _.get(link, 'url.path') === currentPath
                    })
                });
            } else {
                parentPages.push({
                    name: obj.label,
                    url: obj.url.path,
                    current: true
                });
                _.forEach(obj.links, (link) => {
                    childPages.push({
                        name: _.get(link, 'label'),
                        url: _.get(link, 'url.path')
                    })
                });
            }
        }
    }, {childrenPath: 'links'})

    return (
        <React.Fragment>
            <Breadcrumbs key="desktop" parentPages={parentPages} childPages={childPages}/>
            <BreadCrumbsMobile key="mobile" parentPages={parentPages} childPages={childPages}/>
        </React.Fragment>
    )
}

export {Breadcrumbs, BreadCrumbsMobile, BreadCrumbsWrapper};
