import { graphql } from 'gatsby';
import React from "react";

import Layout from "../components/Layout/Layout";
import MetaTags from '../components/MetaTags/MetaTags';
import HeaderImage from '../components/HeaderImage';
import IntroBlock from '../components/IntroBlock';
import ContentContainer from '../components/ContentContainer/ContentContainer';
import { BreadCrumbsWrapper } from '../components/Breadcrumbs';
import Paragraph from '../components/Paragraph/Paragraph';
import MenuContext from '../util/MenuContext';
import _ from 'lodash';
import BaseHead from '../components/BaseHead/BaseHead';

const page = ({ data, pageContext: { language, languageUrls } }) => {
  const node = data.cms.node;
  const alert = _.first(_.get(data, 'alert.nodeQuery.entities', []));
  const breadcrumbs = _.get(data, 'breadcrumbs.menuByName.links', []);
  const headerImage = _.get(node, 'fieldHeaderImage.entity.fieldMediaImage.headerImage.image.childImageSharp', null)
  const headerImageAlt = _.get(node, 'fieldHeaderImage.entity.fieldMediaImage.alt', null);
  const menus = {
    top: _.get(data, 'topMenu.menuByName.links', []),
    footer: _.get(data, 'footerMenu.menuByName.links', []),
    popular: _.get(data, 'popularMenu.menuByName.links', [])
  }

  let currentPath = node.path.alias

  if (language.toLowerCase() != 'nl') {
    currentPath = '/' + language.toLowerCase() + currentPath
  }

  return (
    <MenuContext.Provider value={menus}>
      <Layout language={language} urls={languageUrls} alert={alert} hideSearchOnScroll={true}>
        <main id="content" >
        {headerImage && <HeaderImage image={headerImage} alt={headerImageAlt} />}
          <ContentContainer className="content-page" width="small">
            <h2 className="sr-only">Hoofdinhoud</h2>
            <BreadCrumbsWrapper currentPath={currentPath} language={language.toLowerCase()} breadcrumbs={breadcrumbs} />
            <div className="inner-container">
              <IntroBlock
                title={node.title}
                text={node.fieldIntroText}
              />
              {node.fieldContent.map(({ entity }) => (
                entity.entityId && (
                  // Added key to section because a container needs a key in a list.
                  <section key = {entity.entityId} >
                    <Paragraph key={entity.entityId} entity={entity} />
                  </section>
              )))}
            </div>
          </ContentContainer>
        </main>
      </Layout>
    </MenuContext.Provider>
  )
}

const PageHead = ({ data }) => {
  const node = data.cms.node

  return (
    <BaseHead>
      <MetaTags node={node} />
    </BaseHead>
  )
}

export const Head = PageHead

export const query = graphql`
  query PageQuery ($vid: String!, $language: CMS_LanguageId!) {
    cms {
      node: nodeRevisionById(id: $vid, language: $language) {
        ... Page
      }
    }

    alert: cms {
      nodeQuery(filter: {
        conditions: [
          { operator: EQUAL, field: "type", value: "alert" }
          { operator: EQUAL, field: "status", value: "1" }
        ]
      }, limit: 1) {
        count
        entities(language: $language) {
          entityId
          entityBundle
          ... on CMS_NodeAlert {
            nid
            status
            title
            fieldAllPages
            fieldIntroText
            fieldDetailLink {
              url {
                path
              }
            }
            fieldDismissable
          }
        }
      }
    }

    breadcrumbs: cms {
      menuByName(name:"main", language: $language) {
        links {
          ... Link
          links {
            ... Link
            links {
              ... Link
              links {
                ... Link
                links {
                  ... Link
                  links {
                    ... Link
                    links {
                      ... Link
                      links {
                        ... Link
                        links {
                          ... Link
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    topMenu: cms {
      menuByName(name: "top", language: $language) {
        links {
          label
          url {
            path
          }
        }
      }
    }

    footerMenu: cms {
      menuByName(name: "footer", language: $language) {
        links {
          ... Link
          links {
            ... Link
          }
        }
      }
    }

    popularMenu: cms {
      menuByName(name: "popular", language: $language) {
        links {
          label
          url {
            path
          }
        }
      }
    }
  }

  fragment Page on CMS_NodePage {
    title
    entityMetatags {
      key
      value
    }
    path {
      alias
    }
    fieldIntroText
    fieldHeaderImage {
      entity {
        ...HeaderImage
      }
    }
    fieldContent {
      targetId
      entity {
        entityId
        entityBundle
        ... Wysiwyg
        ... Accordion
        ... ReusableBlock
        ... ReusableContent
        ... InfoBlocks
        ... LinkBlocks
        ... Rijksvideo
        ... Card
        ... LinkList
        ... on CMS_ParagraphFiles {
          fieldBody {
            processed
          }
          fieldFiles {
            entity {
              ... on CMS_MediaDocument {
                entityLabel
                entityId
                fieldMediaFile {
                  entity {
                    url
                    filemime
                    filename
                    filesize
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default page
